.sum_picker {
  .ant-list-item {
    cursor: pointer;
    padding: 5px;
    &.noteLoading {
      background-color: #f0f0f0;
      cursor: default;
    }
  }
  .title {
    text-align: center;
    margin-bottom: 12px;
  }
  .keyboard {
    margin-top: 12px;
    .number_wrapper {
      text-align: center;
      padding: 6px;
      .number {
        font-weight: bold;
        text-align: center;
        background-color: #d9d9d9;
        width: 50px;
        height: 50px;
        padding-top: 18px;
        line-height: 14px;
        display: inline-block;
        border-radius: 50%;
        cursor: pointer;
        &:active {
          background-color: #777777;
        }
      }
      &.number_loading {
        .number {
          background-color: #f0f0f0;
          color: #dedede;
          cursor: default;
        }
      }
    }
  }
  .note_amount {
    padding: 0 10px;
    background-color: #f0f0f0;
    width: 70px;
    text-align: center;
  }
  .selectedNote {
    background-color: #1890ff;
    &.noteLoading {
      background-color: #95ccff;
    }
    .ant-list-item-meta-title {
      color: white !important;
    }
  }
  .total {
    .ant-list-item-meta-title,
    .note_total {
      font-size: 14px;
    }
    .note_total {
      font-weight: bold;
    }
    .total_higher {
      color: green;
    }
    .total_lower {
      color: red;
    }
  }
}

@border-radius-base: 4px;