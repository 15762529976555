.logo {
  height: 32px;
  margin: 16px;
  background: url("../../assets/logo.png") no-repeat center center;
  cursor: pointer;
}

.content-slider {
  overflow: auto;
  height: calc(100vh - 48px);
  position: fixed !important;
  top: 0;
  bottom: 0;
  left: 0;
  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border: none !important;
  }
}

@border-radius-base: 4px;