.shift_cash {
  .ant-card-extra {
    button {
      margin-left: 12px;
    }
  }
  .negative-cell {
    color: red;
    font-weight: bold;
  }
  .positive-cell {
    color: green;
    font-weight: bold;
  }
}

@border-radius-base: 4px;