@import "./vars.less";

.clickable {
  .ant-table-row {
    cursor: pointer;
  }
  .ant-table-row:hover {
    .ant-table-cell {
      background-color: #e6f7ff;
    }
  }
}
