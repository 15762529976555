.penalty {
  color: red;
  font-weight: bold;
}

.bonus {
  color: green;
  font-weight: bold;
}

@border-radius-base: 4px;