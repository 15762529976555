.shift_menu_item {
  margin-bottom: 12px;
  &.active {
    background-color: #1890ff;
    .anticon {
      color: white;
    }
    .title {
      color: white;
    }
  }
  .anticon {
    font-size: 32px;
    float: left;
  }
  .title {
    font-size: 18px;
    float: left;
    padding: 5px 0px 0px 12px;
  }
}

@border-radius-base: 4px;