.mobile__content {
  padding: 20px;
  overflow-y: scroll;
  .slick-dots {
    button {
      background: #2a2a2a !important;
    }
    .slick-active {
      button {
        background: #1677ff !important;
      }
    }
  }
}

@border-radius-base: 4px;