.room {
  margin-bottom: 12px;
  .table {
    cursor: pointer;
    &.selected {
      outline: 4px solid #1890ff;
      z-index: 999;
    }
  }
}

@border-radius-base: 4px;