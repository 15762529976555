.cashier_menu_item {
  text-align: center;
  .anticon {
    font-size: 44px;
    margin-bottom: 12px;
  }
  .title {
    font-size: 18px;
  }
}

@border-radius-base: 4px;