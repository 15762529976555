#root,
.main__wrapper {
  min-height: 550px;
  height: 100%;
}
.main__wrapper {
  margin-left: 280px;
}
.main__wrapper.no-margin {
  margin-left: 0 !important;
}
.main__wrapper.sm-margin {
  margin-left: 80px !important;
}
.ant-page-header-ghost {
  background-color: white !important;
  padding: 20px;
}
.main__content__layout {
  padding: 20px 50px 100px 50px;
  overflow-y: scroll;
  background-color: #d0d9d2;
}
.uploader {
  height: 200px !important;
}
.image-preview {
  height: 200px;
  width: 100%;
  object-fit: cover;
}
.clickable .ant-table-row {
  cursor: pointer;
}
.clickable .ant-table-row:hover .ant-table-cell {
  background-color: #e6f7ff;
}
