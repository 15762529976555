.right-layout {
  border-left: 1px solid #dedede;
}
.order-sider-content {
  background: none !important;
  height: 100%;
  .ant-layout-content {
    padding: 20px;
    overflow-y: auto;
  }
  .ant-layout-footer {
    background: none;
    padding: 10px;
    .buttons {
      text-align: center;
      .ant-btn {
        margin: 0px 5px;
      }
    }
    .summary {
      .ant-divider {
        margin: 0px;
      }
    }
  }
}

.menu-item-picker {
  margin-bottom: 16px;
  .ant-tag {
    cursor: pointer;
  }
}

@border-radius-base: 4px;